.dialog {
  top: 52px;
  left: 230px;
  z-index: 3;
  transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.dialog.fullWidth {
  left: 0;
}

.dialog :global .MuiDialogActions-root {
  border-top: 2px solid var(--color-border-light);
  padding: var(--space-3);
}

.dialog :global .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.dialog :global .MuiDialogActions-root:after {
  content: '';
  order: 1;
  flex: 1;
}

.title {
  display: flex;
  align-items: center;
  padding: 0;
}

.buttons {
  margin-left: auto;
  padding: var(--space-1);
}

.close {
  color: var(--color-border-main);
  padding: var(--space-1);
  background-color: var(--color-border-light);
}

.paper {
  padding-bottom: var(--space-8);
  background-color: var(--color-border-background);
}

@media (min-width: 600px) {
  .dialog :global .MuiDialog-paper {
    min-width: 600px;
    margin: 0;
  }
}

@media (min-width: 900px) {
  .title {
    position: sticky;
    top: 0;
  }
}

@media (max-width: 899.95px) {
  .dialog {
    left: 0;
    top: 0;
    z-index: 1300;
  }

  .dialog :global .MuiDialogActions-root {
    padding: 0;
  }

  .title {
    margin-bottom: var(--space-3);
    background-color: var(--color-background-paper);
  }

  .close {
    background-color: unset;
  }

  .close svg {
    font-size: 1.5rem;
  }
}
