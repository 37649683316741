.container {
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--color-text-primary);
}

.verticalAlign {
  vertical-align: middle;
}
