.list {
  display: grid;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.icon {
  min-width: 0;
  margin-right: var(--space-2);
}

.listItemButton {
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.list :global .MuiListItemButton-root {
  color: var(--color-text-primary);
}

[data-theme='dark'] .list :global .Mui-selected {
  background-color: var(--color-border-light);
}

.list :global .MuiListItemButton-root:hover {
  border-radius: 6px;
  background-color: var(--color-background-light);
}

.list :global .Mui-selected {
  border-radius: 6px;
  background-color: var(--color-background-main);
}

.listItemButton :global .beamer_icon.active {
  top: auto;
  left: 28px;
  bottom: 10px;
  width: 6px;
  height: 6px;
  color: transparent;
}
