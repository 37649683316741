.image {
  display: block;
  width: auto;
}

[data-theme='dark'] .image {
  background: var(--color-secondary-main);
  border-radius: 4px;
  padding: 2px;
}
