.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.container:last-of-type {
  margin-bottom: 0;
}
