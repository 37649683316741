.popup {
  position: fixed;
  z-index: 1300;
  bottom: var(--space-2);
  right: var(--space-2);
  max-width: 400px;
}

.container {
  padding: var(--space-2);
  border-radius: 0 !important;
}

.container label,
.container input {
  user-select: none;
}

@media (max-width: 599.95px) {
  .popup {
    right: 0;
    bottom: 0;
  }
}

.container.inverted {
  background: var(--color-text-primary);
}

.container.inverted,
.container.inverted :global(.MuiCheckbox-root),
.container.inverted a {
  color: var(--color-background-paper);
}

.container.inverted :global(.Mui-checked) {
  color: var(--color-background-paper);
}

.container.inverted :global(.Mui-checked.Mui-disabled) {
  opacity: 0.5;
}
