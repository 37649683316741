.dialogActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 599.95px) {
  .dialogActions {
    flex-direction: column;
    width: 100%;
  }
  .dialogActions > span {
    width: 100%;
  }
}
