.select {
  height: 100%;
}

.select:after,
.select:before {
  display: none;
}

.select *:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.select :global .MuiSelect-select {
  padding-right: 40px !important;
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.select :global .MuiSelect-icon {
  margin-right: var(--space-2);
}

.select :global .Mui-disabled {
  pointer-events: none;
}

.select :global .MuiMenuItem-root {
  padding: 0;
}

.listSubHeader {
  background-color: var(--color-background-main);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  margin-top: var(--space-1);
}

[data-theme='dark'] .undeployedNetworksHeader {
  background-color: var(--color-secondary-background);
}

.plusIcon {
  background-color: var(--color-background-main);
  color: var(--color-border-main);
  border-radius: 100%;
  height: 20px;
  width: 20px;
  padding: 4px;
  margin-left: auto;
}

.newChip {
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: -18px;
  margin-left: -14px;
  transform: scale(0.7);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-1);
  width: 100%;
}

.multiChainChip {
  padding: var(--space-2) 0;
  margin: 2px;
  border-color: var(--color-border-main);
}

.comingSoon {
  background-color: var(--color-border-light);
  border-radius: 4px;
  color: var(--color-text-primary);
  padding: 4px 8px;
}
